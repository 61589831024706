<template>
    <div id="plan" class="container">
 
        <div v-if="pageDone">
            <h1 class="text-center mb-5 fw-bold">BinaryFlash Membership</h1>

            <div v-if="member.isLogin && !subscribed" id="remind-section" class="section pb-5">
                <div class="card">
                    <div class="card p-2 p-md-4">
                        <div class="card-body">
                            <h3 class="title mb-4">HI, {{member.name}}</h3>
                            <p class="mb-0">You haven't subscribed to our service. Enjoy a 7-day free trail before joining the membership, which can be canceled anytime during the subscription period.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="details-section" class="section pb-5">
                <div class="card bg-main-3 p-2 p-md-4">
                    <div class="card-body">
                        <div class="d-flex align-items-center mb-3">
                            <h2 class="fs-1 fw-bold mb-0">$105</h2>
                            <span class="fs-4 opacity-7 ms-3">/ 30 Days</span>
                        </div>
                        <h3 class="fw-bold" v-if="!subscribed">Start your <span class="text-color-highlight">7 days free</span> trial</h3>

                        <hr class="my-5">

                        <div v-if="member.isLogin && subscribed" class="mb-4">
                            <div class="d-flex justify-content-between mb-2">
                                <h6 class="fw-normal opacity-7 mb-0">Subscription Date</h6>
                                <div class="text-end">{{ formatShowDate(memberSubscription.subscription_date) }}</div>
                            </div>

                            <div class="d-flex justify-content-between mb-2">
                                <h6 class="fw-normal opacity-7 mb-0">Effective Till</h6>
                                <div class="text-end">
                                    {{ formatShowDate(memberSubscription.effective_til) }}<br>
                                    <span class="text-pink">(Start 7 days free trial)</span>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between mb-2">
                                <h6 class="fw-normal opacity-7 mb-0">Renewal On</h6>
                                <div class="text-end">End on {{ formatShowDate(memberSubscription.renewal_date) }}</div>
                            </div>

                            <hr class="my-5">
                        </div>

                        <ul class="list mb-4">
                            <li class="fs-5 mb-4">Instant delivery of latest trading signals</li>
                            <li class="fs-5 mb-4">Minimum 6 pairs of major currencies</li>
                            <li class="fs-5 mb-4">6~12 signals in average per trading day</li>
                        </ul>

                        <div v-if="!canceledPlan">
                            <button v-if="member.isLogin && subscribed" class="btn btn-danger text-white btn-lg w-100" data-bs-toggle="modal" data-bs-target="#cancelModal">
                                Cancel Plan
                            </button>
                            <button v-else class="btn btn-main-1 text-white btn-lg w-100" @click="tryFree()">Try free for 7 days</button>
                        </div>
                    
                        <hr class="my-5">

                        <p class="opacity-7 mb-0">Disclaimer: All information related to the signals generated by BinaryFlash are for reference only. They do not constitute actual advices to the trading of any financial instruments and their users should make their own judgements and decisions on how to interpret these signals.  Enjoy a 7-day free trail before joining the membership, which can be cancelled anytime during the subscription period.</p>
                        
                    </div>
                </div>
            </div>
        

            <div id="faq-section" class="section pt-5">
                <h1 class="text-center mb-5 fw-bold">FAQ</h1>

                <FaqList id="faq-1" class="mb-3">
                    <template v-slot:header>What kind of brokers should I use?</template>
                    <template v-slot:content>Apart from choosing a reputed broker, you have to choose one that allows you to set the expiry time of the binary option in terms of hours and minutes, for example 1 hour 37 mins.</template>
                </FaqList> 

                <FaqList id="faq-2" class="mb-3">
                    <template v-slot:header>How should I set the return ratio?</template>
                    <template v-slot:content>You can set your return ratio in each trade to the maximumly available value provided by the broker.  For most brokers it is 90%.  It is because our signal generation engine has taken this parameter into consideration when maximizing your long-term profit and at the same time minimizing your loss by estimating the optimum position size for each trade.  This latter figure, included in our signals for risk management, is a distinctive feature of BinaryFlash.</template>
                </FaqList> 

                <FaqList id="faq-3" class="mb-3">
                    <template v-slot:header>Why not more currency pairs?</template>
                    <template v-slot:content>We focus on currency pairs that are most liquid in the market.  Our research shows that high-quality trading signals are more likely to be generated for major currency pairs than exotics.</template>
                </FaqList>  

                <FaqList id="faq-4" class="mb-3">
                    <template v-slot:header>Why not more signals?</template>
                    <template v-slot:content>Because of the unique properties of binary options, the winning rate of a successful trading strategy should have small fluctuations.  Simply put, a strategy with an almost constant 65% of winning rate will be, in the long run, better than a strategy with winning rate swinging rapidly between 55% and 75%.  Therefore we do not strive to generate excessive number of unreliable signals but signals with robust statistical characteristics.</template>
                </FaqList> 

                <FaqList id="faq-5" class="mb-3">
                    <template v-slot:header>Is BinaryFlash using technical analysis or human traders to generate signals?</template>
                    <template v-slot:content>We employ neither technical analysis nor human traders to generate signals.  Rather we apply our expert knowledge in mathematical finance, AI, and other scientific disciplines to build our signal generation engine, i.e., the approach commonly used by quants working in investment firms large or small.</template>
                </FaqList> 

                <FaqList id="faq-6" class="mb-3">
                    <template v-slot:header>Will BinaryFlash renew my membership automatically? </template>
                    <template v-slot:content>Yes we will renew your 30-day membership automatically through the PayPal’s platform to save your renewal effort and to avoid discontinuation of your receiving signals.  However, you can cancel your subscription anytime you wish and we will continue sending you signals till the end of your subscription period.</template>
                </FaqList>           
            </div>
        </div>
       
        <CancelModal :member-subscription="memberSubscription"></CancelModal>
        <PaypalModal></PaypalModal>

    </div>
</template>

<script>
import { getCookie , formatShowDate} from '@/assets/js/common.js';
import { mapState , mapMutations } from 'vuex';
import FaqList from "@/components/plan/FaqList.vue";
import CancelModal from "@/components/plan/CancelModal.vue";
import PaypalModal from "@/components/plan/PaypalModal.vue";

export default {
    name: 'Plan',
    components: {
		FaqList,
        CancelModal,
        PaypalModal
	},
    data(){
        return{
            pageDone: false,
            subscribed: false,
            canceledPlan: false,

            billingPlans:[],
            customId:"",
            paypalClientId:"",
            paypalPlanId:{},

            memberSubscription:{}
            
        }   
        
    },
    computed:{
		...mapState(['member', 'modals'])
	},
    methods:{
        ...mapMutations(['set_page_loading']),

        formatShowDate(date){
            return formatShowDate(date);
        },

        tryFree(){
            if(this.member.isLogin){
                this.modals.paypalModal.show();

            }else{
                this.modals.login.show();
            }
        },

        // 取得 plans
        getBillingPlans(){

            return new Promise((resolve, reject)=>{

                this.set_page_loading(true);

                this.axios.get('https://binaryflash.codewcy.com/api/site/subscription/plans').then(response => {
                    this.set_page_loading(false);
                    
                    if(response.data.status === 'success'){
                        this.billingPlans = response.data.result.billing_plans;

                        resolve();

                    }else{
                        swal({
                            title: response.data.message,
                            icon: 'error',
                            button: "OK",
                        });

                        reject();
                    }

                }).catch((e)=>{

                    this.set_page_loading(false);
                    swal({
                        title: e,
                        icon: 'error',
                        button: "OK",
                    });

                    reject();

                })

            })

        },

        // 取得 custom_id 及 paypal_client_id
        getCustomId(){
            
            return new Promise((resolve, reject)=>{

                this.set_page_loading(true);

                let token = getCookie('token');
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };

                this.axios.post('https://binaryflash.codewcy.com/api/site/subscription/custom_id', {}, config).then(response => {
                    this.set_page_loading(false);
                    
                    if(response.data.status === 'success'){
                        this.customId = response.data.result.custom_id;
                        this.paypalClientId = response.data.result.paypal_client_id;
                        resolve();

                    }else{
                        swal({
                            title: response.data.message,
                            icon: 'error',
                            button: "OK",
                        });

                        reject();
                    }

                }).catch((e)=>{

                    this.set_page_loading(false);
                    
                    swal({
                        title: e,
                        icon: 'error',
                        button: "OK",
                    });

                    reject();

                })

            })
        },

        // 取得登入者訂閱資訊
        getMemberSubscription(){
            return new Promise((resolve, reject)=>{

                this.set_page_loading(true);

                let token = getCookie('token');
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                this.axios.get('https://binaryflash.codewcy.com/api/site/subscription', config).then(response => {
                    this.set_page_loading(false);
                    
                    if(response.data.status === 'success'){

                        let subscription = response.data.result.subscription;

                        // rookie
                        if(subscription === null){
                            this.subscribed = false;
                            this.canceledPlan = false;
                            let paln = this.billingPlans.find((item)=>{
                                return item.code === 'basic_plan_for_rookie'
                            });

                            this.paypalPlanId = paln.paypal_plan_id;
                            
                        // subscriber
                        }else if(!subscription.canceled_at){
                            this.subscribed = true;
                            this.canceledPlan = false;
                            this.memberSubscription = subscription;
                            this.paypalPlanId = subscription.paypal_plan_id;
                        
                        // canceled
                        }else{
                            this.subscribed = true;
                            this.canceledPlan = true;
                            this.memberSubscription = subscription;
                            this.paypalPlanId = subscription.paypal_plan_id;
                        }

                        resolve();

                    }else{
                        swal({
                            title: response.data.message,
                            icon: 'error',
                            button: "OK",
                        });

                        reject();
                    }

                }).catch((e)=>{

                    this.set_page_loading(false);
                    
                    swal({
                        title: e,
                        icon: 'error',
                        button: "OK",
                    });

                    reject();

                })

            })

        },

        // 產生 paypal 按鈕
        generatePayalBtns(){
            let vm = this;
            let scriptTag = document.createElement("script");

            scriptTag.onload = () => {
                paypal.Buttons({

                    createSubscription: function(data, actions) {

                        return actions.subscription.create({
                            'plan_id': vm.paypalPlanId,
                            'custom_id': vm.customId
                        });

                    },
                    onApprove: function(data, actions) {

                        vm.registerSubscription(data.subscriptionID)
                
                    }
                }).render('#paypal-button-container');
            };

            scriptTag.setAttribute("src",`https://www.paypal.com/sdk/js?client-id=${ this.paypalClientId }&vault=true&intent=subscription`);
            document.querySelector('#plan').appendChild(scriptTag);
        },

        // 申請訂閱 (paypal callback)
        registerSubscription(paypal_subscription_id){
            this.set_page_loading(true);

            let token = getCookie('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            this.axios.post(`https://binaryflash.codewcy.com/api/site/subscription/${paypal_subscription_id}/register`, {} , config).then(response => {
                this.set_page_loading(false);
                
                if(response.data.status === 'success'){
                    window.location.reload();

                }else{
                    swal({
                        title: response.data.message,
                        icon: 'error',
                        button: "OK",
                    });

                }

            }).catch((e)=>{

                this.set_page_loading(false);
                
                swal({
                    title: e,
                    icon: 'error',
                    button: "OK",
                });


            })

        }

        
    },
    created() {
       
        if(this.member.isLogin){
            Promise.all([
                this.getBillingPlans(),
                this.getCustomId(), 
                this.getMemberSubscription()

            ]).then(() => {
                this.generatePayalBtns();
                this.pageDone = true;

            }).catch((err) => {
                console.error(err)
            });
            
        }else{
            this.pageDone = true;
        }
    },
    
}
</script>

<style lang="scss" scoped>
	@import "@/scss/all.scss";

    .section{
        max-width: 40rem;
        margin: auto;
    }

    .card{
        box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }
	
    #details-section{
        .list{
            list-style: none;
            padding-left: 0;

            >li{
                display: flex;
                align-items: center;

                &::before{
                    content: '';
                    display: inline-block;
                    height: 2rem;
                    width: 2rem;
                    background-image: url(~@/assets/img/plan/check-circle.png);
                    background-size: cover;
                    margin-right: 1rem;
                    flex-shrink: 0;
                }
            }

        }
    }

    #remind-section{
        .card{
            background-color: rgba(255, 81, 164, 0.6);
        }

        .title{
            background-image: url(~@/assets/img/plan/chat-dots.png);
            background-size: 2rem;
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 3rem;
        }
    }

</style>
