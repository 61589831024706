

<template>
    <ModalTemplate modalID="cancelModal">
        <template>
            <div class="modal fade" id="cancelModal" tabindex="-1">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header p-3 p-md-4">
                            <h3 class="modal-title text-center w-100">Cancel Membership</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-3 p-md-5">
                            <p class="text-secondary fs-5 fw-nomal lh-base mb-4">We are very sorry to hear this. Hope to see you again soon.</p>

                            <div class="mb-4">

                                <div class="d-flex justify-content-between mb-2">
                                    <h6 class="fw-normal text-secondary mb-0">Subscription Date</h6>
                                    <div class="text-end">{{ formatShowDate(memberSubscription.subscription_date) }}</div>
                                </div>

                                <div class="d-flex justify-content-between mb-2">
                                    <h6 class="fw-normal text-secondary mb-0">Effective Till</h6>
                                    <div class="text-end">
                                        {{ formatShowDate(memberSubscription.effective_til) }}<br>
                                        <span class="text-pink">(Start 7 days free trial)</span>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <h6 class="fw-normal text-secondary mb-0">Renewal On</h6>
                                    <div class="text-end">End on {{ formatShowDate(memberSubscription.renewal_date) }}</div>
                                </div>

                            </div>

                            <div class="text-end border-top pt-4">
                                <button class="btn btn-lg btn-danger text-white" @click="cancelPlan()">Cancel Plan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </ModalTemplate>
</template>

<script>
import { getCookie , formatShowDate } from '@/assets/js/common.js';
import { mapMutations } from 'vuex';
import ModalTemplate from "@/components/modals/ModalTemplate.vue";

export default {
    name: 'cancelModal',
    components: {
		ModalTemplate,
	},
    props:['memberSubscription'],

    methods:{
        ...mapMutations(['set_page_loading']),

        cancelPlan(){
            
            this.set_page_loading(true);
            let token = getCookie('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            this.axios.post(`https://binaryflash.codewcy.com/api/site/subscription/${this.memberSubscription.paypal_subscription_id}/cancel`, {} , config).then(response => {
                this.set_page_loading(false);
                
                if(response.data.status === 'success'){
                    
                    window.location.reload();

                }else{
                    swal({
                        title: response.data.message,
                        icon: 'error',
                        button: "OK",
                    });

                }

            }).catch((e)=>{

                this.set_page_loading(false);
                
                swal({
                    title: e,
                    icon: 'error',
                    button: "OK",
                });


            })
        },

        formatShowDate(date){
            return formatShowDate(date);
        }
    },
}
</script>