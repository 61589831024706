

<template>
    <ModalTemplate modalID="paypalModal">
        <template>
            <div class="modal fade" id="paypalModal" tabindex="-1">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header p-3 p-md-4">
                            <h3 class="modal-title text-center w-100">Subscribe</h3>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body p-3 p-md-5">
                            <div id="paypal-button-container"></div>
                        </div>

                    </div>
                </div>
            </div>
        </template>
    </ModalTemplate>
    
</template>

<script>
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
export default {
    name: 'PaypalModal',
    components: {
		ModalTemplate,
	},
}
</script>