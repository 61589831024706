<template>
  <div class="card bg-main-3 p-2 p-md-4">
        <div class="card-header d-flex justify-content-between align-items-start border-0">
            <h4 class="mb-0 pe-3">
                <slot name='header'></slot>
            </h4>
            
            <a class="collapes-btn collapsed" data-bs-toggle="collapse" :href="'#' + id" role="button"></a>
        </div>
        <div :id="id" class="collapse">
            <div  class="card-body">
                <p class="opacity-7 mb-0">
                    <slot name='content'></slot>
                </p>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
  name: 'FaqList',
  props:{
        id:{
            required:true
        }
  }
}
</script>

<style lang="scss" scoped>
    @import "@/scss/all.scss";

    .collapes-btn{
        
        flex-shrink: 0;
        background-size: cover;

        &.collapsed{
            background-image: url(~@/assets/img/plan/plus-circle.png);
        }

        &:not(.collapsed){
            background-image: url(~@/assets/img/plan/dash-circle.png);
        }

        @include media-breakpoint-up(md) { 
            width: 2rem;
            height: 2rem;
        }

        @include media-breakpoint-down(md) { 
            width: 1.5rem;
            height: 1.5rem;
        }
    }

</style>
